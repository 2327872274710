import React, {useContext} from 'react';
import clsx from 'clsx';
import {
  Box, Container, Grid, Typography, useTheme,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {graphql, PageProps} from 'gatsby';
import PreBreadcrumbs from '../../shared-components/preBreadcrumbs';
import Markdown from '../../shared-components/markdown';
import Meta from '../../shared-components/meta';
import {LegalAgreementContext} from './LegalAgreementContext';
import {TranslationService} from "../../services/translationService";
import {translationServiceContext} from "../../services/provider";

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    paddingBottom: theme.spacing(3),
  },
  containerPaddingTop: {
    paddingTop: theme.spacing(2),
  },
  containerPaddingTopSm: {
    paddingTop: theme.spacing(1),
  },
  breadcrumbPaper: {
    marginBottom: theme.spacing(2),
  },
  breadcrumbPaperSm: {
    margin: `0px ${theme.spacing(1)}px ${theme.spacing(1)}px 0px`,
  },
  smContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  link: {
    color: 'black',
    textDecoration: 'underline',
  },
  itemsHeader: {
    paddingBottom: theme.spacing(2),
  },
  smGridWrapper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

interface Props {
  readonly general: {
    readonly frontmatter: {
      readonly title: string;
      readonly slogan?: string;
      readonly description?: string;
    };
  };
}

const LegalAgreement: React.FC<PageProps<Props, LegalAgreementContext>> = (props) => {
  // Styles
  const theme: Theme = useTheme();
  const classes = useStyles();

  // mobile first
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  const translationService: TranslationService = useContext<TranslationService>(translationServiceContext);

  const siteTitle: string = props.data.general.frontmatter.title;
  const pageWithSiteTitle = `${props.pageContext.title} | ${siteTitle}`;

  const pageCanonicalURL = `${process.env.GATSBY_APP_URL}/${props.pageContext.slug}/`;

  return (
    <>
      <Meta title={pageWithSiteTitle} siteTitle={siteTitle} url={pageCanonicalURL}/>
      <Container
        className={clsx(classes.container, isDownSm ? classes.containerPaddingTopSm : classes.containerPaddingTop, isDownSm ? classes.smContainer : undefined)}
        disableGutters={isDownSm}
      >

        <Box className={isDownSm ? classes.breadcrumbPaperSm : classes.breadcrumbPaper}>
            <PreBreadcrumbs isDownSm={isDownSm} items={[
              {
                link: '/',
                title: translationService.translate('BREADCRUMB_HOME')
              },
              {
                title: props.pageContext.title
              }
            ]}/>
          </Box>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.itemsHeader}
          >
            <Grid item direction="column" xs container>
              <Grid item>
                <Typography variant="h5" component="h1">
                  {props.pageContext.title}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Markdown>{props.pageContext.text}</Markdown>
      </Container>
    </>
  );
};

export default LegalAgreement;

export const pageDataQuery = graphql`
  query LegalAgreementData {
    general: markdownRemark(fileAbsolutePath: { regex: "/settings/general.md$/" }) {
      frontmatter {
        title
      }
    }
  }
`;
