import React from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {Link} from 'gatsby-theme-material-ui';
import {BreadcrumbEntry} from '../models/breadcrumbEntry';
import {Variant} from "@material-ui/core/styles/createTypography";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {Theme} from "@material-ui/core";

interface Props {
  readonly items: ReadonlyArray<BreadcrumbEntry>;
  readonly isDownSm: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
}));

const PreBreadcrumbs: React.FC<Props> = ({items, isDownSm}) => {

  const classes = useStyles();

  const typographyVariant: Variant = isDownSm ? "body2" : "body1";

  return (
    <Breadcrumbs separator="/" aria-label="breadcrumb">
      {items.map((entry: BreadcrumbEntry, index) => {
        if (index === items.length - 1) {
          return (
            <Typography variant={typographyVariant} color="textPrimary" noWrap>{entry.title}</Typography>
          )
        } else {
          return (
            <Link key={entry.link} color="inherit" to={entry.link}>
              <Typography variant={typographyVariant} style={{textDecoration: "underline"}} noWrap>
                {entry.title}
              </Typography>
            </Link>
          )
        }
      })}
    </Breadcrumbs>
  );
};

export default PreBreadcrumbs;
